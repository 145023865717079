import { template as template_61a8015e53664e6795a85e64f2f7892c } from "@ember/template-compiler";
const SidebarSectionMessage = template_61a8015e53664e6795a85e64f2f7892c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

import { template as template_6248a4515fc84b4b8166991b9990188a } from "@ember/template-compiler";
const FKText = template_6248a4515fc84b4b8166991b9990188a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
